<template>
  <fade-transition>
    <div v-if="visible" class="loading-overlay">
      <i class="fas fa-spinner fa-spin loading"></i>
    </div>
  </fade-transition>
</template>
<script>
  import { FadeTransition } from 'vue2-transitions';

  export default {
    name: 'base-loading',
    components: {
      FadeTransition
    },
    props: {
      visible: {
        type: Boolean,
        default: () => false,
        description: "Visible status"
      }
    },
    methods: {
      appears() {
        this.visible = true;
      },
      disappears() {
        this.visible = false;
      }
    }
  }
</script>
