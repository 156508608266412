<template>
  <div class="position-relative">
    <base-loading v-bind:visible="loading"></base-loading>
    <card>
      <div class="row align-items-end">
        <div class="col-lg-2">
          <base-input label="Année">
            <select @change="onChangeYear($event)" class="form-control">
              <option
                v-for="year in years"
                v-bind:key="year"
                v-bind:value="year"
                v-bind:selected="year == selectedYear"
                >{{ year }}</option
              >
            </select>
          </base-input>
        </div>
        <div class="col-lg-5">
          <base-input label="Action DPC">
            <select @change="onChangeCourseTag($event)" class="form-control">
              <option
                v-for="coursesTag in coursesTags"
                v-bind:key="coursesTag.id"
                v-bind:value="coursesTag.id"
                v-bind:selected="coursesTag.id == selectedCourseTag"
                >{{ coursesTag.name }}</option
              >
            </select>
          </base-input>
        </div>
        <div class="col-lg-5">
          <base-input label="Session">
            <select @change="onChangeCourse($event)" class="form-control">
              <option
                v-for="course in coursesInCourseTag"
                v-bind:key="course.id"
                v-bind:value="course.id"
                v-bind:selected="course.id == selectedCourse"
                >{{ course.title }}</option
              >
            </select>
          </base-input>
        </div>
      </div>
    </card>

    <div class="row">
      <div class="col-lg-4">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{ $t("dashboard.completedTasks") }}</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-trophy text-success "></i>
              {{ progressionAvg }}% terminées
            </h3>
          </template>
          <div class="chart-area">
            <pie-chart
              style="height: 100%"
              chart-id="purple-pie-chart"
              :chart-data="purplePieChart.chartData"
              :gradient-stops="purplePieChart.gradientStops"
              :extra-options="purplePieChart.extraOptions"
            >
            </pie-chart>
          </div>
        </card>
      </div>

      <div class="col-lg-4">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Statut des inscrits</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-chart-pie-36 text-success "></i> Libéraux
              <span class="text-success">{{ liberals }}</span> | Salariés
              <span class="text-primary">{{ employees }}</span>
            </h3>
          </template>
          <div class="chart-area">
            <pie-chart
              style="height: 100%"
              chart-id="purple-pie-chart"
              :chart-data="purplePieChart2.chartData"
              :gradient-stops="purplePieChart2.gradientStops"
              :extra-options="purplePieChart2.extraOptions"
            >
            </pie-chart>
          </div>
        </card>
      </div>

      <div class="col-lg-4">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Temps moyen passé sur les EPP</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-laptop text-info"></i>
              {{ quizzesTimespentAvg }}
            </h3>
            <base-dropdown
              menu-on-right=""
              tag="div"
              title-classes="btn btn-link btn-icon"
              aria-label="Info dropdown"
            >
              <i slot="title" class="tim-icons icon-zoom-split"></i>
              <li>Temps passé pris en compte si action terminée</li>
            </base-dropdown>
          </template>
        </card>

        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Détails du temps passé</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-cloud-download-93 text-info"></i>Export
              ANDPC
            </h3>

            <div class="card-body" style="padding-top: 0;">
              <div
                class="d-flex flex-row gap align-items-center justify-content-center mb-2"
              >
                <p class="label"><strong>Utilisateurs :</strong></p>
                <label class="d-flex gap-s">
                  <input type="radio" value="" v-model="userJobStatus" />
                  <span class="caption">Tous</span>
                </label>
                <label class="d-flex gap-s">
                  <input
                    type="radio"
                    value="liberaux"
                    v-model="userJobStatus"
                  />
                  <span class="caption">Libéraux</span>
                </label>
              </div>

              <div class="row justify-content-center">
                <div class="col-auto">
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="exportLogs('')"
                    :disabled="!this.timelogs"
                  >
                    Export détaillé
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="exportLogs('units')"
                    :disabled="!this.timelogs"
                  >
                    Export détaillé par unité
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="exportReport"
                    :disabled="!this.timelogs"
                  >
                    Export du rapport
                  </button>
                </div>
              </div>
            </div>
          </template>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <card class="card">
          <template slot="header">
            <h4 slot="header" class="card-title">Liste des inscrits</h4>
            <h3 class="card-title">
              <i class="tim-icons icon-single-02 text-success"></i> Total :
              {{ usersCount }}
            </h3>
          </template>
          <vue-good-table
            :columns="usersTable.columns"
            :rows="usersTable.rows"
            :fixed-header="true"
            :search-options="{
              enabled: true,
              placeholder: 'Rechercher'
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
              perPageDropdown: [10, 20, 30, 40],
              dropdownAllowAll: true,
              nextLabel: 'Suivant',
              prevLabel: 'Précédent',
              rowsPerPageLabel: 'Inscrits par page',
              ofLabel: 'de',
              pageLabel: 'page',
              allLabel: 'Tous'
            }"
            styleClass="vgt-table striped"
          />
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import PieChart from "@/components/Charts/PieChart";
import * as chartConfigs from "@/components/Charts/config";
import BaseLoading from "../components/BaseLoading.vue";
import { VueGoodTable } from "vue-good-table";

// import the styles
import "vue-good-table/dist/vue-good-table.css";

// Helpers
import l_filter from "lodash/filter";
import l_includes from "lodash/includes";
import l_map from "lodash/map";
import l_countBy from "lodash/countBy";
import l_mean from "lodash/mean";

export default {
  name: "Dpc",
  components: {
    PieChart,
    BaseLoading,
    VueGoodTable
  },
  data() {
    return {
      userJobStatus: "",
      years: this.$store.state.years,
      selectedYear: this.$store.state.selectedYear,
      selectedCourseTag: this.$store.state.selectedCourseTag,
      selectedCourse: this.$store.state.selectedCourse,
      coursesInCourseTag: [],
      courses: this.$store.state.courses,
      coursesTags: this.$store.state.coursesTags,
      users: this.$store.state.users,
      timelogs: this.$store.state.timelogs,
      liberals: "•",
      employees: "•",
      progressionAvg: "•",
      purplePieChart: {
        extraOptions: chartConfigs.greenChartOptions,
        chartData: {
          labels: [
            " Actions DPC terminées",
            " Actions DPC en cours",
            " Actions DPC non commencée"
          ],
          datasets: [
            {
              label: "Complétion actions DPC",
              fill: true,
              borderColor: ["#019e7c", "#1d8cf8", "#ee0064"],
              borderWidth: 2,
              backgroundColor: [
                "rgba(1, 159, 125, 0.07)",
                "rgba(0, 98, 165, 0.07)",
                "rgba(238, 0, 100, 0.07)"
              ],
              data: [0, 0, 0]
            }
          ]
        }
      },
      purplePieChart2: {
        extraOptions: chartConfigs.greenChartOptions,
        chartData: {
          labels: [" Libéraux", " Salariés"],
          datasets: [
            {
              label: "Proportion libéraux / salariés",
              fill: true,
              borderColor: ["#019e7c", "#ee0064"],
              borderWidth: 2,
              backgroundColor: [
                "rgba(1, 159, 125, 0.07)",
                "rgba(238, 0, 100, 0.07)"
              ],
              data: [0, 0]
            }
          ]
        }
      },
      loading: false
    };
  },

  computed: {
    usersCount() {
      return Object.keys(this.users).length;
    },

    usersTable() {
      var table = {
        columns: [
          {
            label: "Inscrit",
            field: "full_name"
          },
          {
            label: "Email",
            field: "email"
          },
          {
            label: "Statut",
            field: "status"
          },
          {
            label: "État",
            field: "progression",
            type: "number"
          },
          // {
          //   label: "Temps passé EPP",
          //   field: "timespent",
          //   type: "number",
          //   tooltip: "(Quizs) Seulement si action DPC terminée, sinon 0 min"
          // },
          {
            label: "Temps passé (session)",
            field: "timelog",
            type: "number",
            tooltip: "Temps passé sur les étapes de la session"
          }
        ],
        rows: []
      };

      var users = this.users ?? [];

      for (let index = 0; index < users.length; index++) {
        const user = users[index];
        const status = user.status == "liberal" ? "Libéral" : "Salarié";

        // const timespent =
        //   user.progression.quizzes_timespent !== undefined
        //     ? Math.ceil(user.progression.quizzes_timespent / 60)
        //     : 0;

        // Timelogs
        let globalCourseTimeStr = "";
        if (this.timelogs) {
          let userTimelogs = this.timelogs.filter(t => {
            let user_stat_ids = t.user_stat_ids
              ? t.user_stat_ids.split(",")
              : null;
            if (!user_stat_ids) return false;
            return user_stat_ids.includes(user.id);
          });

          if (userTimelogs.length) {
            // sum course steps time (in seconds)
            let globalCourseTime = userTimelogs.reduce((acc, t) => {
              let time = parseInt(t.time) < 0 ? 0 : parseInt(t.time);
              return acc + time;
            }, 0);

            // format to " hh:mm:ss" string
            if (globalCourseTime > 0) {
              globalCourseTimeStr = this.getHHMMSSFromSeconds(globalCourseTime);
            }
          }
        }

        table["rows"].push({
          full_name: user.full_name,
          email: user.email,
          status: status,
          progression: user.progression.percentage + "%",
          // timespent: timespent + " min",
          timelog: globalCourseTimeStr
        });
      }

      return table;
    },

    quizzesTimespentAvg() {
      var users = this.users ?? [];
      var timespents = [];

      for (let index = 0; index < users.length; index++) {
        const user = users[index];
        const timespent =
          user.progression.quizzes_timespent !== undefined
            ? Math.ceil(user.progression.quizzes_timespent / 60)
            : 0;
        if (timespent !== 0) timespents.push(timespent);
      }

      if (timespents.length <= 0) return "-";

      return Math.round(l_mean(timespents)) + " min";
    }
  },

  methods: {
    /**
     * Format seconds to hh:mm:ss
     *
     * @param {int} totalSeconds
     * @return string
     */
    getHHMMSSFromSeconds(totalSeconds) {
      if (!totalSeconds) {
        return "00:00:00";
      }
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      const hhmmss =
        this.padTo2(hours) +
        ":" +
        this.padTo2(minutes) +
        ":" +
        this.padTo2(seconds);
      return hhmmss;
    },

    // Convert single digit to double digit
    padTo2(value) {
      if (!value) {
        return "00";
      }
      return value < 10 ? String(value).padStart(2, "0") : value;
    },

    changeCoursesInCourseTag() {
      const selected = String(this.selectedCourseTag);
      var coursesIn = l_filter(this.courses, function(o) {
        return l_includes(o.courseCategories, selected);
      });
      coursesIn.unshift({
        id: 0,
        courseCategories: selected,
        registeredUsers: "allCourses",
        title: "Toutes les sessions"
      });
      this.coursesInCourseTag = coursesIn;
    },

    onChangeYear(event) {
      this.$store.commit("CHANGE_YEAR", event.target.value);
      this.selectedYear = event.target.value;

      //empty both select instead ?!
      this.mainPromise(event.target.value, event);
    },
    onChangeCourseTag(event) {
      this.$store.commit("CHANGE_COURSE_TAG", event.target.value);
      this.selectedCourseTag = event.target.value;
      this.changeCoursesInCourseTag();

      this.$store.commit("CHANGE_COURSE", 0);
      this.selectedCourse = 0;

      this.usersPromise(this.selectedYear);
    },
    onChangeCourse(event) {
      this.$store.commit("CHANGE_COURSE", event.target.value);
      this.selectedCourse = event.target.value;
      this.usersPromise(this.selectedYear);
    },

    mainPromise(year, event) {
      this.loading = true;
      Promise.all([this.getCoursesTag(year), this.getCourses(year)])
        .catch(error => {
          this.$notify({
            message: error,
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            timeout: 5000
          });
        })
        .finally(() => {
          // Only when from a change event or first init of all time
          if (
            event !== undefined ||
            this.selectedCourseTag == null ||
            this.selectedCourseTag == 0
          ) {
            if (this.coursesTags[0] !== undefined) {
              this.selectedCourseTag = this.coursesTags[0]["id"];
              this.$store.commit(
                "CHANGE_COURSE_TAG",
                this.coursesTags[0]["id"]
              );
            } else {
              this.selectedCourseTag = 0;
              this.$store.commit("CHANGE_COURSE_TAG", 0);
            }
            this.selectedCourse = 0;
            this.$store.commit("CHANGE_COURSE", 0);
          }
          this.changeCoursesInCourseTag();
          this.usersPromise(year);
        });
    },

    usersPromise(year) {
      this.loading = true;

      var courses = "";
      if (this.selectedCourse != 0) {
        courses += this.selectedCourse;
      } else {
        const selected = String(this.selectedCourseTag);
        const coursesIn = l_filter(this.courses, function(o) {
          return l_includes(o.courseCategories, selected);
        });
        const coursesInIds = l_map(coursesIn, "id");
        courses = coursesInIds.join(",");
      }

      Promise.all([
        this.getUsersInCourses(year, courses)
        // this.getTimelogs(year, courses)
      ])
        .catch(error => {
          this.$notify({
            message: error,
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            timeout: 5000
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getCoursesTag(year) {
      return this.$store
        .dispatch("GET_COURSES_TAGS", year)
        .then(response => {
          this.coursesTags = response;
        })
        .catch(error => {
          throw error;
        });
    },

    getCourses(year) {
      return this.$store
        .dispatch("GET_COURSES", { year: year, filters: "" })
        .then(response => {
          this.courses = response;
        })
        .catch(error => {
          throw error;
        });
    },

    getUsersInCourses(year, courses) {
      return this.$store
        .dispatch("GET_USERS", {
          year: year,
          filters: "?filters[courses]=" + courses
        })
        .then(response => {
          this.users = response;

          // Progression => custom because only 100 or 0 is accepted in the average

          const progressionsPercentages = l_map(
            l_map(response, "progression"),
            "percentage"
          );

          let sumEnded = 0;
          let nbEnded = 0;
          let nbBegining = 0;
          for (let i = 0; i < progressionsPercentages.length; i++) {
            if (progressionsPercentages[i] == 100) {
              sumEnded += 100;
              nbEnded++;
            }
            if (progressionsPercentages[i] == 0) {
              nbBegining++;
            }
          }
          this.progressionAvg = Math.ceil(
            sumEnded / (progressionsPercentages.length || 1)
          );

          // purplePieChart actu
          var temp = { ...this.purplePieChart.chartData };
          temp.datasets[0].data = [
            nbEnded,
            progressionsPercentages.length - nbEnded - nbBegining,
            nbBegining
          ];
          this.purplePieChart.chartData = temp;

          // Liberals / Salaries

          const allStatus = l_map(response, "status");
          const countStatus = l_countBy(allStatus, function(status) {
            return status == "liberal";
          });
          this.liberals = countStatus["true"] ?? 0;
          this.employees = countStatus["false"] ?? 0;

          // purplePieChar2 actu
          var temp = { ...this.purplePieChart2.chartData };
          temp.datasets[0].data = [this.liberals, this.employees];
          this.purplePieChart2.chartData = temp;
        })
        .catch(error => {
          throw error;
        });
    },

    getTimelogs(year, courses) {
      return this.$store
        .dispatch("GET_TIMELOGS", {
          year: year,
          filters: "?filters[courses]=" + courses
        })
        .then(response => {
          this.timelogs = response;
        })
        .catch(error => {
          throw error;
        });
    },

    /**
     * Export timelogs report to XLSX
     *
     * @return void
     */
    exportReport() {
      this.loading = true;

      var courses = "";
      if (this.selectedCourse != 0) {
        courses += this.selectedCourse;
      } else {
        const selected = String(this.selectedCourseTag);
        const coursesIn = l_filter(this.courses, function(o) {
          return l_includes(o.courseCategories, selected);
        });
        const coursesInIds = l_map(coursesIn, "id");
        courses = coursesInIds.join(",");
      }

      let courses_str = courses.replace(",", "_");

      let timetag = new Date()
        .toLocaleString()
        .replaceAll("/", "")
        .replaceAll(":", "")
        .replaceAll(" ", "-");

      let jobStatus = this.userJobStatus;

      let filename = jobStatus
        ? `rapport-andpc-${timetag}-${courses_str}-${jobStatus}.xlsx`
        : `rapport-andpc-${timetag}-${courses_str}-tous.xlsx`;

      let userIDs = this.users ? this.users.map(u => parseInt(u.id)) : [];

      return this.$store
        .dispatch("EXPORT_TIMELOGS", {
          year: this.selectedYear,
          filters: `?filters[courses]=${courses}&filters[type]=report&filters[jobStatus]=${jobStatus}`,
          userIDs: userIDs
        })
        .then(response => {
          this.loading = false;
          this.downloadFile(response, filename);
        })
        .catch(error => {
          this.loading = false;
          throw error;
        });
    },

    /**
     * Export timelogs to XLSX
     *
     * @return void
     */
    exportLogs(mode = "") {
      this.loading = true;
      var courses = "";

      if (this.selectedCourse != 0) {
        courses += this.selectedCourse;
      } else {
        const selected = String(this.selectedCourseTag);
        const coursesIn = l_filter(this.courses, function(o) {
          return l_includes(o.courseCategories, selected);
        });
        const coursesInIds = l_map(coursesIn, "id");
        courses = coursesInIds.join(",");
      }

      let courses_str = courses.replace(",", "_");

      let timetag = new Date()
        .toLocaleString()
        .replaceAll("/", "")
        .replaceAll(":", "")
        .replaceAll(" ", "-");

      let jobStatus = this.userJobStatus;

      let prefix = mode === "units" ? "unites" : "logs";
      let ext = mode === "units" ? "zip" : "xlsx";

      let filename = jobStatus
        ? `${prefix}-andpc-${timetag}-${courses_str}-${jobStatus}.${ext}`
        : `${prefix}-andpc-${timetag}-${courses_str}-tous.${ext}`;

      let userIDs = this.users ? this.users.map(u => parseInt(u.id)) : [];

      let action =
        mode === "units" ? "EXPORT_TIMELOGS_UNITS" : "EXPORT_TIMELOGS";

      return this.$store
        .dispatch(action, {
          year: this.selectedYear,
          filters: `?filters[courses]=${courses}&filters[jobStatus]=${jobStatus}`,
          userIDs: userIDs
        })
        .then(response => {
          this.loading = false;
          this.downloadFile(response, filename);
        })
        .catch(error => {
          this.loading = false;
          throw error;
        });
    },

    /**
     * Download requested file (blob)
     *
     * @param {string} content
     * @param {string} fileName
     * @return void
     */
    downloadFile(content, fileName) {
      const URL = window.URL || window.webkitURL;
      const href = URL.createObjectURL(
        new Blob([content], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        })
      );
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      link.click();
    }
  },

  mounted() {
    this.mainPromise(this.selectedYear);
    this.changeCoursesInCourseTag();

    // Get selected course-stat-id's
    // let courses = "";
    // if (this.selectedCourse != 0) {
    //   courses += this.selectedCourse;
    // } else {
    //   const selected = String(this.selectedCourseTag);
    //   const coursesIn = l_filter(this.courses, function(o) {
    //     return l_includes(o.courseCategories, selected);
    //   });
    //   const coursesInIds = l_map(coursesIn, "id");
    //   courses = coursesInIds.join(",");
    // }

    // // Fetch users timelogs for each courses
    // this.getTimelogs(this.selectedYear, courses);
  },

  beforeDestroy() {}
};
</script>
<style></style>
