<template>
  <div class="position-relative">
    <base-loading v-bind:visible="loading"></base-loading>
    <card>
      <div class="row align-items-end">
        <div class="col-lg-2">
          <base-input label="Année">
            <select @change="onChangeYear($event)" class="form-control">
              <option v-for="year in years" v-bind:key="year" v-bind:value="year" v-bind:selected="year == selectedYear">{{year}}</option>
            </select>
          </base-input>
        </div>
        <div class="col-lg-5">
          <base-input label="Action DPC">
            <select @change="onChangeCourseTag($event)" class="form-control">
              <option v-for="coursesTag in coursesTags" v-bind:key="coursesTag.id" v-bind:value="coursesTag.id" v-bind:selected="coursesTag.id == selectedCourseTag">{{coursesTag.name}}</option>
            </select>
          </base-input>
        </div>
        <div class="col-lg-5">
          <base-input label="Session">
            <select @change="onChangeCourse($event)" class="form-control">
              <option v-for="course in coursesInCourseTag" v-bind:key="course.id" v-bind:value="course.id" v-bind:selected="course.id == selectedCourse">{{course.title}}</option>
            </select>
          </base-input>
        </div>
      </div>
    </card>

    <div class="row">
      <div class="col-lg-6 col-xl-3">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Nombre de questionnaires d'impact</h5>
            <h3 class="card-title"><i class="tim-icons icon-notes text-success"></i> {{qisCount}}</h3>
          </template>
        </card>
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Pourcentage de satisfaction général</h5>
            <h3 class="card-title"><i class="tim-icons icon-trophy text-success"></i> {{globalSatisfaction}}%</h3>
          </template>
        </card>
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Temps moyen de traitement</h5>
            <h3 class="card-title"><i class="tim-icons icon-watch-time text-success"></i> {{closedTime}}</h3>
          </template>
        </card>
      </div>

      <div class="col-lg-6 col-xl-3">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Question 1</h5>
            <base-dropdown menu-on-right=""
                           tag="div"
                           title-classes="btn btn-link btn-icon"
                           aria-label="Info dropdown"
                          >
              <i slot="title" class="tim-icons icon-zoom-split"></i>
              <li>Ce programme a été utile à ma pratique professionnelle et m’a apporté des éléments pour améliorer la qualité et la sécurité de prise en charge de mes patients</li>
            </base-dropdown>
          </template>
          <div class="chart-area">
            <bar-chart style="height: 100%"
                      :chart-data="q1BarChart.chartData"
                      :gradient-color="q1BarChart.gradientColors"
                      :gradient-stops="q1BarChart.gradientStops"
                      :extra-options="q1BarChart.extraOptions">
            </bar-chart>
          </div>
        </card>
      </div>

      <div class="col-lg-6 col-xl-3">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Question 2</h5>
            <base-dropdown menu-on-right=""
                           tag="div"
                           title-classes="btn btn-link btn-icon"
                           aria-label="Info dropdown"
                          >
              <i slot="title" class="tim-icons icon-zoom-split"></i>
              <li>Les apports de ce programme sont extrapolables et/ou utilisables au-delà des thèmes étudiés</li>
            </base-dropdown>
          </template>
          <div class="chart-area">
            <bar-chart style="height: 100%"
                      :chart-data="q2BarChart.chartData"
                      :gradient-color="q2BarChart.gradientColors"
                      :gradient-stops="q2BarChart.gradientStops"
                      :extra-options="q2BarChart.extraOptions">
            </bar-chart>
          </div>
        </card>
      </div>

      <div class="col-lg-6 col-xl-3">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Question 3</h5>
            <base-dropdown menu-on-right=""
                           tag="div"
                           title-classes="btn btn-link btn-icon"
                           aria-label="Info dropdown"
                          >
              <i slot="title" class="tim-icons icon-zoom-split"></i>
              <li>Ce programme m'a incité à m'engager de façon pérenne dans une démarche continue</li>
            </base-dropdown>
          </template>
          <div class="chart-area">
            <bar-chart style="height: 100%"
                      :chart-data="q3BarChart.chartData"
                      :gradient-color="q3BarChart.gradientColors"
                      :gradient-stops="q3BarChart.gradientStops"
                      :extra-options="q3BarChart.extraOptions">
            </bar-chart>
          </div>
        </card>
      </div>

      <div class="col-lg-6 col-xl-3">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Question 4</h5>
            <base-dropdown menu-on-right=""
                           tag="div"
                           title-classes="btn btn-link btn-icon"
                           aria-label="Info dropdown"
                          >
              <i slot="title" class="tim-icons icon-zoom-split"></i>
              <li>Ce programme m'a fourni des éléments pour améliorer le travail de coopération au sein de mon équipe</li>
            </base-dropdown>
          </template>
          <div class="chart-area">
            <bar-chart style="height: 100%"
                      :chart-data="q4BarChart.chartData"
                      :gradient-color="q4BarChart.gradientColors"
                      :gradient-stops="q4BarChart.gradientStops"
                      :extra-options="q4BarChart.extraOptions">
            </bar-chart>
          </div>
        </card>
      </div>

      <div class="col-lg-6 col-xl-3">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Question 5</h5>
            <base-dropdown menu-on-right=""
                           tag="div"
                           title-classes="btn btn-link btn-icon"
                           aria-label="Info dropdown"
                          >
              <i slot="title" class="tim-icons icon-zoom-split"></i>
              <li>L'organisation logistique était satisfaisante</li>
            </base-dropdown>
          </template>
          <div class="chart-area">
            <bar-chart style="height: 100%"
                      :chart-data="q5BarChart.chartData"
                      :gradient-color="q5BarChart.gradientColors"
                      :gradient-stops="q5BarChart.gradientStops"
                      :extra-options="q5BarChart.extraOptions">
            </bar-chart>
          </div>
        </card>
      </div>

      <div class="col-lg-6 col-xl-3">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Question 6</h5>
            <base-dropdown menu-on-right=""
                           tag="div"
                           title-classes="btn btn-link btn-icon"
                           aria-label="Info dropdown"
                          >
              <i slot="title" class="tim-icons icon-zoom-split"></i>
              <li>Ce programme était compatible avec ma charge de travail habituelle</li>
            </base-dropdown>
          </template>
          <div class="chart-area">
            <bar-chart style="height: 100%"
                      :chart-data="q6BarChart.chartData"
                      :gradient-color="q6BarChart.gradientColors"
                      :gradient-stops="q6BarChart.gradientStops"
                      :extra-options="q6BarChart.extraOptions">
            </bar-chart>
          </div>
        </card>
      </div>

      <div class="col-lg-6 col-xl-3">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Question 7</h5>
            <base-dropdown menu-on-right=""
                           tag="div"
                           title-classes="btn btn-link btn-icon"
                           aria-label="Info dropdown"
                          >
              <i slot="title" class="tim-icons icon-zoom-split"></i>
              <li>Je recommanderais ce type de programme à mes collègues</li>
            </base-dropdown>
          </template>
          <div class="chart-area">
            <bar-chart style="height: 100%"
                      :chart-data="q7BarChart.chartData"
                      :gradient-color="q7BarChart.gradientColors"
                      :gradient-stops="q7BarChart.gradientStops"
                      :extra-options="q7BarChart.extraOptions">
            </bar-chart>
          </div>
        </card>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <card class="card">
          <h4 slot="header" class="card-title">Questionnaires d'impact</h4>
          <vue-good-table
            :columns="qisTable.columns"
            :rows="qisTable.rows"
            :fixed-header="true"
            :search-options="{
              enabled: true,
              placeholder: 'Rechercher',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
              perPageDropdown: [10, 20, 30, 40],
              dropdownAllowAll: true,
              nextLabel: 'Suivant',
              prevLabel: 'Précédent',
              rowsPerPageLabel: 'Questionnaires par page',
              ofLabel: 'de',
              pageLabel: 'page',
              allLabel: 'Tous'
            }"
            styleClass="vgt-table striped"
            >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'link'">
                <a v-bind:href="props.row.link" target="_blank">Voir le questionnaire</a>
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
          </vue-good-table>
        </card>
      </div>
      <div class="col-lg-4">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">État des questionnaires d'impact</h5>
            <h3 class="card-title"><i class="tim-icons icon-shape-star text-success "></i> {{closedPercentage}}% traités</h3>
          </template>
          <div class="chart-area">
            <pie-chart style="height: 100%"
                        chart-id="purple-pie-chart"
                        :chart-data="purplePieChart.chartData"
                        :gradient-stops="purplePieChart.gradientStops"
                        :extra-options="purplePieChart.extraOptions">
            </pie-chart>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import BarChart from '@/components/Charts/BarChart';
  import PieChart from '@/components/Charts/PieChart';
  import * as chartConfigs from '@/components/Charts/config';
  import BaseLoading from '../components/BaseLoading.vue';
  import { VueGoodTable } from 'vue-good-table';

  // import the styles
  import 'vue-good-table/dist/vue-good-table.css'

  // Helpers
  import l_filter from "lodash/filter";
  import l_includes from "lodash/includes";
  import l_map from "lodash/map";
  import l_forEach from "lodash/forEach";
  import l_sum from "lodash/sum";

  export default {
    components: {
      BarChart,
      PieChart,
      BaseLoading,
      VueGoodTable
    },
    data() {
      return {
        years: this.$store.state.years,
        selectedYear: this.$store.state.selectedYear,
        selectedCourseTag: this.$store.state.selectedCourseTag,
        selectedCourse: this.$store.state.selectedCourse,
        coursesInCourseTag: [],
        courses: this.$store.state.courses,
        coursesTags: this.$store.state.coursesTags,
        qis: this.$store.state.qis,

        q1BarChart: {
          extraOptions: chartConfigs.blueChartOptions,
          chartData: {
            labels: ['OUI', 'NON'],
            datasets: [{
              label: " ",
              fill: true,
              borderColor: ["#019e7c", "#ee0064"],
              borderWidth: 2,
              backgroundColor: ["rgba(1, 159, 125, 0.07)", "rgba(238, 0, 100, 0.07)"],
              data: [0, 0],
            }]
          },
          gradientColors: ['rgba(29,140,248,0.2)', 'rgba(29,140,248,0.0)', 'rgba(29,140,248,0)'],
          gradientStops: [1, 0.4, 0],
        },
        q2BarChart: {
          extraOptions: chartConfigs.blueChartOptions,
          chartData: {
            labels: ['OUI', 'NON'],
            datasets: [{
              label: " ",
              fill: true,
              borderColor: ["#019e7c", "#ee0064"],
              borderWidth: 2,
              backgroundColor: ["rgba(1, 159, 125, 0.07)", "rgba(238, 0, 100, 0.07)"],
              data: [0, 0],
            }]
          },
          gradientColors: ['rgba(29,140,248,0.2)', 'rgba(29,140,248,0.0)', 'rgba(29,140,248,0)'],
          gradientStops: [1, 0.4, 0],
        },
        q3BarChart: {
          extraOptions: chartConfigs.blueChartOptions,
          chartData: {
            labels: ['OUI', 'NON'],
            datasets: [{
              label: " ",
              fill: true,
              borderColor: ["#019e7c", "#ee0064"],
              borderWidth: 2,
              backgroundColor: ["rgba(1, 159, 125, 0.07)", "rgba(238, 0, 100, 0.07)"],
              data: [0, 0],
            }]
          },
          gradientColors: ['rgba(29,140,248,0.2)', 'rgba(29,140,248,0.0)', 'rgba(29,140,248,0)'],
          gradientStops: [1, 0.4, 0],
        },
        q4BarChart: {
          extraOptions: chartConfigs.blueChartOptions,
          chartData: {
            labels: ['OUI', 'NON'],
            datasets: [{
              label: " ",
              fill: true,
              borderColor: ["#019e7c", "#ee0064"],
              borderWidth: 2,
              backgroundColor: ["rgba(1, 159, 125, 0.07)", "rgba(238, 0, 100, 0.07)"],
              data: [0, 0],
            }]
          },
          gradientColors: ['rgba(29,140,248,0.2)', 'rgba(29,140,248,0.0)', 'rgba(29,140,248,0)'],
          gradientStops: [1, 0.4, 0],
        },
        q5BarChart: {
          extraOptions: chartConfigs.blueChartOptions,
          chartData: {
            labels: ['OUI', 'NON'],
            datasets: [{
              label: " ",
              fill: true,
              borderColor: ["#019e7c", "#ee0064"],
              borderWidth: 2,
              backgroundColor: ["rgba(1, 159, 125, 0.07)", "rgba(238, 0, 100, 0.07)"],
              data: [0, 0],
            }]
          },
          gradientColors: ['rgba(29,140,248,0.2)', 'rgba(29,140,248,0.0)', 'rgba(29,140,248,0)'],
          gradientStops: [1, 0.4, 0],
        },
        q6BarChart: {
          extraOptions: chartConfigs.blueChartOptions,
          chartData: {
            labels: ['OUI', 'NON'],
            datasets: [{
              label: " ",
              fill: true,
              borderColor: ["#019e7c", "#ee0064"],
              borderWidth: 2,
              backgroundColor: ["rgba(1, 159, 125, 0.07)", "rgba(238, 0, 100, 0.07)"],
              data: [0, 0],
            }]
          },
          gradientColors: ['rgba(29,140,248,0.2)', 'rgba(29,140,248,0.0)', 'rgba(29,140,248,0)'],
          gradientStops: [1, 0.4, 0],
        },
        q7BarChart: {
          extraOptions: chartConfigs.blueChartOptions,
          chartData: {
            labels: ['OUI', 'NON'],
            datasets: [{
              label: " ",
              fill: true,
              borderColor: ["#019e7c", "#ee0064"],
              borderWidth: 2,
              backgroundColor: ["rgba(1, 159, 125, 0.07)", "rgba(238, 0, 100, 0.07)"],
              data: [0, 0],
            }]
          },
          gradientColors: ['rgba(29,140,248,0.2)', 'rgba(29,140,248,0.0)', 'rgba(29,140,248,0)'],
          gradientStops: [1, 0.4, 0],
        },

        purplePieChart: {
          extraOptions: chartConfigs.greenChartOptions,
          chartData: {
            labels: [' Traités', ' Non traités'],
            datasets: [{
              label: "Complétion actions DPC",
              fill: true,
              borderColor: ["#019e7c", "#ee0064"],
              borderWidth: 2,
              backgroundColor: ["rgba(1, 159, 125, 0.07)", "rgba(238, 0, 100, 0.07)"],
              data: [0, 0],
            }]
          },
        },
        loading: false
      }
    },
    computed: {
      computedQis(){
          var qis = this.qis ?? {};

          var computedQis = qis.map(qi => {
            var newQI = {};
            newQI.qi_data = {};

            l_forEach(qi.qi_data , function(value, key) {
              switch (key) {
                case "Ce programme a été utile à ma pratique professionnelle et m’a apporté des éléments pour améliorer la qualité et la sécurité de prise en charge de mes patients":
                  newQI.qi_data['q1'] = value;
                  break;
                case "Les apports de ce programme sont extrapolables et/ou utilisables au-delà des thèmes étudiés":
                  newQI.qi_data['q2'] = value;
                  break;
                case "Ce programme m'a incité à m'engager de façon pérenne dans une démarche continue":
                  newQI.qi_data['q3'] = value;
                  break;
                case "Ce programme m'a fourni des éléments pour améliorer le travail de coopération au sein de mon équipe":
                  newQI.qi_data['q4'] = value;
                  break;
                case "L'organisation logistique était satisfaisante":
                  newQI.qi_data['q5'] = value;
                  break;
                case "Ce programme était compatible avec ma charge de travail habituelle":
                  newQI.qi_data['q6'] = value;
                  break;
                case "Je recommanderais ce type de programme à mes collègues":
                  newQI.qi_data['q7'] = value;
                  break;
              }
            });

            return newQI;
        });
        return computedQis;
      },
      qisCount(){
        return Object.keys(this.qis).length;
      },
      globalSatisfaction(){ // No, isn't a title from Daft Punk

        var qis = this.computedQis;
        const qisLenght = Object.keys(qis).length;

        var globalSatisfaction = 0; // sum

        for (let index = 0; index < qisLenght; index++) {
          const qi = qis[index];
          const qiDataLenght = Object.keys(qi.qi_data).length;
          const positivesQuestions = l_filter(qi.qi_data , function(o) {
            return o==='Oui';
          });
          const positiveLenght = Object.keys(positivesQuestions).length;

          globalSatisfaction += Math.ceil( (positiveLenght / (qiDataLenght || 1)) * 100 );
        }

        globalSatisfaction = Math.ceil( globalSatisfaction / (qisLenght || 1) );

        return globalSatisfaction;
      },
      closedTimeArray(){
        const qis = l_filter(this.qis , function(o) {
          return o.closed_time!==null;
        });

        const closedTimes = l_map(qis,  function(qi) {
          return parseInt(qi.closed_time) ?? 1;
        });

        return closedTimes;
      },
      closedTime(){
        const closedTimes = this.closedTimeArray;
        const closedTimesLenght = Object.keys(closedTimes).length;

        if(closedTimesLenght < 1) return 'Aucun traitement';

        var closedTime = Math.round( (l_sum(closedTimes) / (closedTimesLenght || 1)) );

        if(closedTime > 0){
          closedTime += ' jours';
        }
        else{
          closedTime = 'Moins d\'une journée';
        }

        return closedTime;
      },
      closedPercentage(){
        const closedTimes = this.closedTimeArray;
        const closedTimesLenght = Object.keys(closedTimes).length;

        return Math.ceil( (closedTimesLenght / (this.qisCount || 1)) * 100 );
      },
      qisTable(){
        var table = {
          columns: [
            {
              label: 'Inscrit',
              field: 'full_name'
            },
            {
              label: 'État',
              field: 'status'
            },
            {
              label: 'Questionnaire',
              field: 'link',
              tdClass: 'text-right',
              thClass: 'text-right'
            }
          ],
          rows: []
        };
        var qis = this.qis ?? {};

        for (let index = 0; index < qis.length; index++) {
          const qi = qis[index];
          const status = qi.closed_time!==null ? 'Traité' : 'Non traité';
          table["rows"].push({"full_name": qi.full_name, "status": status, "link" : qi.permalink });
        }

        return table;
      }
    },
    methods: {

      changeCoursesInCourseTag(){
        const selected = String(this.selectedCourseTag);
        var coursesIn = l_filter(this.courses , function(o) {
          return l_includes(o.courseCategories, selected);
        });
        coursesIn.unshift(
          {
            id: 0,
            courseCategories: selected,
            registeredUsers: 'allCourses',
            title: "Toutes les sessions"
          }
        );
        this.coursesInCourseTag = coursesIn;
      },

      onChangeYear(event) {
        this.$store.commit('CHANGE_YEAR', event.target.value);
        this.selectedYear = event.target.value;

        this.mainPromise(event.target.value, event);
      },
      onChangeCourseTag(event) {
        this.$store.commit('CHANGE_COURSE_TAG', event.target.value);
        this.selectedCourseTag = event.target.value;
        this.changeCoursesInCourseTag();

        this.$store.commit('CHANGE_COURSE', 0);
        this.selectedCourse = 0;

        this.qisPromise(this.selectedYear);
      },
      onChangeCourse(event) {
        this.$store.commit('CHANGE_COURSE', event.target.value);
        this.selectedCourse = event.target.value;
        this.qisPromise(this.selectedYear);
      },

      mainPromise(year, event) {
        this.loading = true;
        Promise.all([
          this.getCoursesTag(year),
          this.getCourses(year)
        ]).catch((error) => {
          this.$notify({message: error, type: 'danger', verticalAlign: 'top', horizontalAlign: 'center', timeout: 5000});
        }).finally(() => {
          // Only when from a change event or first init of all time
          if(event!==undefined || this.selectedCourseTag==null){
            if(this.coursesTags[0]!==undefined){
              this.selectedCourseTag = this.coursesTags[0]['id'];
              this.$store.commit('CHANGE_COURSE_TAG', this.coursesTags[0]['id']);
            }
            else{
              this.selectedCourseTag = 0;
              this.$store.commit('CHANGE_COURSE_TAG', 0);
            }
            this.selectedCourse = 0;
            this.$store.commit('CHANGE_COURSE', 0);
          }
          this.changeCoursesInCourseTag();
          this.qisPromise(year);
        });
      },

      qisPromise(year) {
        this.loading = true;

        var courses = '';
        if(this.selectedCourse!=0){
          courses += this.selectedCourse;
        }else if(this.selectedCourseTag!=0){
          const selected = String(this.selectedCourseTag);
          const coursesIn = l_filter(this.courses, function(o) {
            return l_includes(o.courseCategories, selected);
          });
          const coursesInIds = l_map(coursesIn, 'id');
          courses = coursesInIds.join(",");
        }

        Promise.all([
          this.getQis(year, courses)
        ]).catch((error) => {
          this.$notify({message: error, type: 'danger', verticalAlign: 'top', horizontalAlign: 'center', timeout: 5000});
        }).finally(() => {
          this.loading = false;
        });
      },


      getCoursesTag(year) {
        return this.$store
        .dispatch("GET_COURSES_TAGS", year)
        .then((response) => {
          response.unshift(
            {
              id: 0,
              name: "Toutes les actions DPC"
            }
          );
          this.coursesTags = response;
        })
        .catch((error) => {
          throw error;
        });
      },

      getCourses(year) {
        return this.$store
        .dispatch("GET_COURSES", {year: year, filters: ''})
        .then((response) => {
          this.courses = response;
        })
        .catch((error) => {
          throw error;
        });
      },

      getQis(year, courses) {
        const filtersCourses = courses!=='' ? '?filters[courses]='+courses : courses;
        return this.$store
        .dispatch("GET_QIS", {year: year, filters: filtersCourses})
        .then((response) => {
          this.qis = response;

          const qisDatas = l_map(this.computedQis, 'qi_data');

          var questionsArray = {};
          questionsArray['q1'] = l_map(qisDatas, 'q1');
          questionsArray['q2'] = l_map(qisDatas, 'q2');
          questionsArray['q3'] = l_map(qisDatas, 'q3');
          questionsArray['q4'] = l_map(qisDatas, 'q4');
          questionsArray['q5'] = l_map(qisDatas, 'q5');
          questionsArray['q6'] = l_map(qisDatas, 'q6');
          questionsArray['q7'] = l_map(qisDatas, 'q7');

          const qiLenght = Object.keys(qisDatas).length;

          const q1Positives = l_filter(questionsArray['q1'] , function(o) {
            return o==='Oui';
          });
          const q2Positives = l_filter(questionsArray['q2'] , function(o) {
            return o==='Oui';
          });
          const q3Positives = l_filter(questionsArray['q3'] , function(o) {
            return o==='Oui';
          });
          const q4Positives = l_filter(questionsArray['q4'] , function(o) {
            return o==='Oui';
          });
          const q5Positives = l_filter(questionsArray['q5'] , function(o) {
            return o==='Oui';
          });
          const q6Positives = l_filter(questionsArray['q6'] , function(o) {
            return o==='Oui';
          });
          const q7Positives = l_filter(questionsArray['q7'] , function(o) {
            return o==='Oui';
          });

          const q1PositivesLenght = Object.keys(q1Positives).length;
          const q2PositivesLenght = Object.keys(q2Positives).length;
          const q3PositivesLenght = Object.keys(q3Positives).length;
          const q4PositivesLenght = Object.keys(q4Positives).length;
          const q5PositivesLenght = Object.keys(q5Positives).length;
          const q6PositivesLenght = Object.keys(q6Positives).length;
          const q7PositivesLenght = Object.keys(q7Positives).length;

          const q1Satisfaction = Math.ceil( (q1PositivesLenght / (qiLenght || 1)) * 100 );
          const q2Satisfaction = Math.ceil( (q2PositivesLenght / (qiLenght || 1)) * 100 );
          const q3Satisfaction = Math.ceil( (q3PositivesLenght / (qiLenght || 1)) * 100 );
          const q4Satisfaction = Math.ceil( (q4PositivesLenght / (qiLenght || 1)) * 100 );
          const q5Satisfaction = Math.ceil( (q5PositivesLenght / (qiLenght || 1)) * 100 );
          const q6Satisfaction = Math.ceil( (q6PositivesLenght / (qiLenght || 1)) * 100 );
          const q7Satisfaction = Math.ceil( (q7PositivesLenght / (qiLenght || 1)) * 100 );

          // q1 actu
          var temp = {...this.q1BarChart.chartData};
          temp.datasets[0].data = [q1Satisfaction, 100-q1Satisfaction];
          this.q1BarChart.chartData = temp;

          // q2 actu
          temp = {...this.q2BarChart.chartData};
          temp.datasets[0].data = [q2Satisfaction, 100-q2Satisfaction];
          this.q2BarChart.chartData = temp;

          // q3 actu
          temp = {...this.q3BarChart.chartData};
          temp.datasets[0].data = [q3Satisfaction, 100-q3Satisfaction];
          this.q3BarChart.chartData = temp;

          // q4 actu
          temp = {...this.q4BarChart.chartData};
          temp.datasets[0].data = [q4Satisfaction, 100-q4Satisfaction];
          this.q4BarChart.chartData = temp;

          // q5 actu
          temp = {...this.q5BarChart.chartData};
          temp.datasets[0].data = [q5Satisfaction, 100-q5Satisfaction];
          this.q5BarChart.chartData = temp;

          // q6 actu
          temp = {...this.q6BarChart.chartData};
          temp.datasets[0].data = [q6Satisfaction, 100-q6Satisfaction];
          this.q6BarChart.chartData = temp;

          // q7 actu
          temp = {...this.q7BarChart.chartData};
          temp.datasets[0].data = [q7Satisfaction, 100-q7Satisfaction];
          this.q7BarChart.chartData = temp;

          // pie actu
          const closedTimes = this.closedTimeArray;
          const closedTimesLenght = Object.keys(closedTimes).length;

          temp = {...this.purplePieChart.chartData};
          temp.datasets[0].data = [closedTimesLenght, qiLenght-closedTimesLenght];
          this.purplePieChart.chartData = temp;
        })
        .catch((error) => {
          throw error;
        });
      }

    },
    mounted() {
      this.mainPromise(this.selectedYear);
      this.changeCoursesInCourseTag();
    }
  };
</script>
<style>
</style>
