<template>
  <div class="position-relative">
    <base-loading v-bind:visible="loading"></base-loading>
    <card>
      <div class="row align-items-end">
        <div class="col-lg-4">
          <base-input label="Année">
            <select @change="onChangeYear($event)" class="form-control">
              <option v-for="year in years" v-bind:key="year" v-bind:value="year" v-bind:selected="year == selectedYear">{{year}}</option>
            </select>
          </base-input>
        </div>
        <div class="col-lg-4 offset-lg-4">
          <p style="text-align:right;">Mise à jour le {{this.$store.state.updateTime}}</p>
        </div>
      </div>
    </card>

    <card type="chart">
      <template slot="header">
        <h5 class="card-category">{{$t('dashboard.totalShipments')}}</h5>
        <h3 class="card-title"><i class="tim-icons icon-single-02 text-primary"></i> Total : {{globalStats.stats_members.total}}</h3>
      </template>
      <div class="chart-area">
        <line-chart style="height: 100%"
                    chart-id="purple-line-chart"
                    :chart-data="purpleLineChart.chartData"
                    :gradient-colors="purpleLineChart.gradientColors"
                    :gradient-stops="purpleLineChart.gradientStops"
                    :extra-options="purpleLineChart.extraOptions">
        </line-chart>
      </div>
    </card>

    <div class="row">
      <div class="col-lg-4">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Statut des inscrits</h5>
            <h3 class="card-title"><i class="tim-icons icon-chart-pie-36 text-success "></i> Libéraux <span class="text-success">{{globalStats.stats_members.liberals}}</span> | Salariés <span class="text-primary">{{globalStats.stats_members.employees}}</span></h3>
          </template>
          <div class="chart-area">
            <pie-chart style="height: 100%"
                        chart-id="purple-pie-chart2"
                        :chart-data="purplePieChart2.chartData"
                        :gradient-stops="purplePieChart2.gradientStops"
                        :extra-options="purplePieChart2.extraOptions">
            </pie-chart>
          </div>
        </card>
      </div>
      <div class="col-lg-8">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">Âge des inscrits</h5>
            <h3 class="card-title"><i class="tim-icons icon-badge text-info"></i> Âge moyen : {{globalStats.stats_members.averageAge}} ans</h3>
          </template>
          <div class="chart-area">
            <bar-chart-g style="height: 100%"
                        chart-id="blue-bar-chart"
                        :chart-data="blueBarChart.chartData"
                        :gradient-stops="blueBarChart.gradientStops"
                        :extra-options="blueBarChart.extraOptions">
            </bar-chart-g>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-xl">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.nbActions')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-book-bookmark text-info"></i> {{globalStats.stats_courses.countCourseTags}}</h3>
          </template>
        </card>
      </div>

      <div class="col-12 col-sm-6 col-xl">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.nbSessions')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-calendar-60 text-info"></i> {{globalStats.stats_courses.countCourses}}</h3>
          </template>
        </card>
      </div>

      <div class="col-12 col-sm-4 col-xl">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.minJoin')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-alert-circle-exc text-primary"></i> {{globalStats.stats_courses.minRegistered}}</h3>
          </template>
        </card>
      </div>

      <div class="col-12 col-sm-4 col-xl">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.maxJoin')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-shape-star text-success "></i> {{globalStats.stats_courses.maxRegistered}}</h3>
          </template>
        </card>
      </div>

      <div class="col-12 col-sm-4 col-xl">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.avgJoin')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-chart-bar-32 text-info"></i> {{globalStats.stats_courses.averageRegistered}}</h3>
          </template>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <card class="card">
          <h4 slot="header" class="card-title">{{$t('dashboard.topFlopTableTitle')}}</h4>
          <base-table :data="topFlopTable.data"
                      :columns="topFlopTable.columns"
                      thead-classes="text-primary">
          </base-table>
        </card>
      </div>
      <div class="col-lg-4">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.completedTasks')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-trophy text-success "></i> {{globalStats.stats_courses.averageProgression}}% terminées</h3>
          </template>
          <div class="chart-area">
            <pie-chart style="height: 100%"
                        chart-id="purple-pie-chart"
                        :chart-data="purplePieChart.chartData"
                        :gradient-stops="purplePieChart.gradientStops"
                        :extra-options="purplePieChart.extraOptions">
            </pie-chart>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import LineChart from '@/components/Charts/LineChart';
  import PieChart from '@/components/Charts/PieChart';
  import BarChartGradient from '@/components/Charts/BarChartGradient';
  import * as chartConfigs from '@/components/Charts/config';
  import { BaseTable } from "@/components";
  import config from '@/config';
  import BaseLoading from '../components/BaseLoading.vue';

  export default {
    components: {
      LineChart,
      PieChart,
      'bar-chart-g' : BarChartGradient,
      BaseTable,
      BaseLoading
    },
    data() {
      return {
        years: this.$store.state.years,
        selectedYear: this.$store.state.selectedYear,
        globalStats: this.$store.state.globalStats,
        topFlopCourses: this.$store.state.topFlopCourses,
        purpleLineChart: {
          extraOptions: chartConfigs.purpleChartOptions,
          chartData: {
            labels: ['JAN', 'FÉV', 'MAR', 'AVR', 'MAI', 'JUN', 'JUL', 'AOÛ', 'SEP', 'OCT', 'NOV', 'DEC'],
            datasets: [{
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: this.$store.state.globalStats.stats_members.monthly,
            }]
          },
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.2, 0],
        },
        purplePieChart: {
          extraOptions: chartConfigs.greenChartOptions,
          chartData: {
            labels: [' Actions DPC terminées', ' Actions DPC non terminées'],
            datasets: [{
              label: "Complétion actions DPC",
              fill: true,
              borderColor: ["#019e7c", "#ee0064"],
              borderWidth: 2,
              backgroundColor: ["rgba(1, 159, 125, 0.07)", "rgba(238, 0, 100, 0.07)"],
              data: [this.$store.state.globalStats.stats_courses.endedCourses, this.$store.state.globalStats.stats_courses.totalRegistration-this.$store.state.globalStats.stats_courses.endedCourses],
            }]
          },
        },
        purplePieChart2: {
          extraOptions: chartConfigs.greenChartOptions,
          chartData: {
            labels: [' Libéraux', ' Salariés'],
            datasets: [{
              label: "Proportion libéraux / salariés",
              fill: true,
              borderColor: ["#019e7c", "#ee0064"],
              borderWidth: 2,
              backgroundColor: ["rgba(1, 159, 125, 0.07)", "rgba(238, 0, 100, 0.07)"],
              data: [this.$store.state.globalStats.stats_members.liberals, this.$store.state.globalStats.stats_members.employees],
            }]
          },
        },
        blueBarChart: {
          extraOptions: chartConfigs.barChartOptions,
          chartData: {
            labels: ['25-34', '35-44', '45-54', '55-64', '65 et +'],
            datasets: [{
              label: " Nombre de membres ",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: this.$store.state.globalStats.stats_members.intervalsAge,
            }]
          },
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.4, 0],
        },
        loading: false
      }
    },
    computed: {
      bigLineChartCategories() {
        return this.$t('dashboard.chartCategories');
      },
      topFlopTable(){
        var topFlopTable = {"data": [], "columns": ["Sessions", "Inscrits", "Score"] };
        var topFlopCourses = this.topFlopCourses;

        if(topFlopCourses === null) return topFlopTable;

        for (let index = 0; index < topFlopCourses.length; index++) {
          const course = topFlopCourses[index];
          const score = index > 2 ? "Flop" : "Top";
          topFlopTable["data"].push({"id": index, "sessions": course.title, "inscrits": course.registeredUsers.length, "score" : score });
        }

        return topFlopTable;
      }
    },
    methods: {
      onChangeYear(event) {
        this.$store.commit('CHANGE_YEAR', event.target.value);
        this.mainPromise(event.target.value);
      },

      mainPromise(year) {
        this.loading = true;
        Promise.all([
          this.getGlobalStats(year),
          this.getCourses(year)
        ])
        .catch((error) => {
          this.$notify({message: error, type: 'danger', verticalAlign: 'top', horizontalAlign: 'center', timeout: 5000});
        }).finally(() => {
          this.loading = false;
        });
      },

      getGlobalStats(year) {
        return this.$store
        .dispatch("GET_GLOBAL_STATS", year)
        .then((response) => {
          //console.log(response);

          // all classic stats
          this.globalStats = response;

          // purpleLineChart actu
          var temp = {...this.purpleLineChart.chartData};
          temp.datasets[0].data = response.stats_members.monthly;
          this.purpleLineChart.chartData = temp;

          // purplePieChart2 actu
          temp = {...this.purplePieChart2.chartData};
          temp.datasets[0].data = [response.stats_members.liberals, response.stats_members.employees];
          this.purplePieChart2.chartData = temp;

          // blueBarChart actu
          temp = {...this.blueBarChart.chartData};
          temp.datasets[0].data = response.stats_members.intervalsAge;
          this.blueBarChart.chartData = temp;

          // purplePieChart actu
          temp = {...this.purplePieChart.chartData};
          temp.datasets[0].data = [response.stats_courses.endedCourses, this.$store.state.globalStats.stats_courses.totalRegistration-response.stats_courses.endedCourses];
          this.purplePieChart.chartData = temp;
        })
        .catch((error) => {
          throw error;
        });
      },

      getCourses(year) {
        return this.$store
        .dispatch("GET_COURSES", {year: year, filters: '?filters[special]=topflop'})
        .then((response) => {
          this.topFlopCourses = response;
        })
        .catch((error) => {
          throw error;
        });
      }

    },
    mounted() {
      this.mainPromise(this.selectedYear);
    },
    beforeDestroy() {

    }
  };
</script>
<style>
</style>
